/* Generated by Font Squirrel (https://www.fontsquirrel.com) on March 30, 2017 */



@font-face {
  font-family: 'fabrikat-bold';
  src: url('../../fonts/fabrikat/fabrikat_bold-webfont.eot');
  src: url('../../fonts/fabrikat/fabrikat_bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/fabrikat/fabrikat_bold-webfont.woff2') format('woff2'),
  url('../../fonts/fabrikat/fabrikat_bold-webfont.woff') format('woff'),
  url('../../fonts/fabrikat/fabrikat_bold-webfont.ttf') format('truetype'),
  url('../../fonts/fabrikat/fabrikat_bold-webfont.svg#fabrikatbold') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'fabrikat-italic';
  src: url('../../fonts/fabrikat/fabrikat_italic-webfont.eot');
  src: url('../../fonts/fabrikat/fabrikat_italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/fabrikat/fabrikat_italic-webfont.woff2') format('woff2'),
  url('../../fonts/fabrikat/fabrikat_italic-webfont.woff') format('woff'),
  url('../../fonts/fabrikat/fabrikat_italic-webfont.ttf') format('truetype'),
  url('../../fonts/fabrikat/fabrikat_italic-webfont.svg#fabrikatitalic') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'fabrikat-light';
  src: url('../../fonts/fabrikat/fabrikat_light-webfont.eot');
  src: url('../../fonts/fabrikat/fabrikat_light-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/fabrikat/fabrikat_light-webfont.woff2') format('woff2'),
  url('../../fonts/fabrikat/fabrikat_light-webfont.woff') format('woff'),
  url('../../fonts/fabrikat/fabrikat_light-webfont.ttf') format('truetype'),
  url('../../fonts/fabrikat/fabrikat_light-webfont.svg#fabrikatlight') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'fabrikat-medium';
  src: url('../../fonts/fabrikat/fabrikat_medium-webfont.eot');
  src: url('../../fonts/fabrikat/fabrikat_medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/fabrikat/fabrikat_medium-webfont.woff2') format('woff2'),
  url('../../fonts/fabrikat/fabrikat_medium-webfont.woff') format('woff'),
  url('../../fonts/fabrikat/fabrikat_medium-webfont.ttf') format('truetype'),
  url('../../fonts/fabrikat/fabrikat_medium-webfont.svg#fabrikatmedium') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'fabrikat-regular';
  src: url('../../fonts/fabrikat/fabrikat_regular-webfont.eot');
  src: url('../../fonts/fabrikat/fabrikat_regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/fabrikat/fabrikat_regular-webfont.woff2') format('woff2'),
  url('../../fonts/fabrikat/fabrikat_regular-webfont.woff') format('woff'),
  url('../../fonts/fabrikat/fabrikat_regular-webfont.ttf') format('truetype'),
  url('../../fonts/fabrikat/fabrikat_regular-webfont.svg#fabrikatregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
