////////////////////////////////////////////////////////////////////////////////
// General styles
////////////////////////////////////////////////////////////////////////////////

.display-none {
  display: none;
}

////////////////////////////////////////////////////////////////////////////////
// Specific styles
////////////////////////////////////////////////////////////////////////////////

.auth-sidebar {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  height: 100%;
  width: 465px;

  body.app--popup-style & {
    display: none;
  }
  @media screen and (max-width: 989px) {
    display: none;
  }

  &__logo_rhinov {
    position: absolute;
    top: 45px;
    left: 60px;
    width: 146px;
    height: 29px;
    background-image: url("../../img/logo_rhinov_w.png");
    z-index: 9999;
  }

  &__logo_mdm {
    position: absolute;
    top: 45px;
    left: 60px;
    width: 166px;
    height: 68px;
    background-image: url("../../img/logo_mdm_w.png");
    z-index: 9999;
  }

  &__content {
    position: fixed;
  }

  &__carousel {
    height: 100%;
    min-height: 100%;
  }
  /*&__carousel,
  .slick-list, .slick-track, .slick-active, .slick-active > div {
    height: 100%;
    min-height: 100%;
  }

  .slick-active {
    left: 0 !important;
  }*/
  &__carousel-item {
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("../../img/bg_sidebar_1.jpg") no-repeat center;
    background-size: cover;
    //float: left;
    height: 100%;
    min-height: 100%;
    width: 465px;

    &--1 {
      background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("../../img/bg_sidebar_1.jpg") no-repeat center;
    }

    &--2 {
      background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("../../img/bg_sidebar_2.jpg") no-repeat center;
    }

    &--3 {
      background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("../../img/bg_sidebar_3.jpg") no-repeat center;
    }
  }

  &__carousel-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 30px;
    left: 0;
    text-align: center;
    width: 100%;
    z-index: 9999;
  }

  &__carousel-nav-item {
    background: transparent;
    border: 1px solid @color-gold;
    .border-radius(100%);
    display: inline-block;
    height: 12px;
    margin: 0 10px;
    text-indent: -9999px;
    width: 12px;

    &:hover {
      cursor: pointer;
    }

    &--active {
      background: @color-gold;
    }
  }

  &__cta-box {
    color: #FFF;
    background-color: rgba(60, 59, 63, 0.5);
    border: solid 5px rgba(218, 218, 218, 0.5);
    display: none;
    height: 350px;
    margin: -175px 0 0 -195px;
    position: absolute;
    bottom: 100px;
    left: 50%;
    text-align: center;
    width: 390px;

    h3 {
      margin-top: 90px;
      margin-bottom: 20px;
    }

    p {
      margin: 0;
    }

    .btn-cta {
      margin-top: 60px;
    }
  }
}

.auth-content {
  padding: 30px 30px 30px 495px;
  @media screen and (max-width: 989px) {
    padding-left: 30px;
  }

  body.app--popup-style & {
    padding: 0 30px;
  }
}

.auth-template {
  padding-top: 20px;

  body.app--popup-style & {
    padding: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    /*align-items: flex-end;*/
    justify-content: space-between;
  }

  &__header {

    body.app--popup-style & {
      float: none;
      margin: 30px 0;
      text-align: center;
    }

    a {
      height: 42px;
      font-size: 16px;
      padding: 0;
      margin-left: 10px;
      text-align: center;
      line-height: 2.5;
      cursor: pointer;
    }

    div a {
      margin-top: 10px;
    }

    .do-not-you-have-an-account-yet {
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        margin: 10px auto;
      }
    }

    @media screen and (max-width: 467px) {
      text-align: center;

      .return-label {
        display: none;
      }

      a {
        margin-top: 20px;
      }
    }
  }
}

.auth-form {
  max-width: 435px;
  margin: 100px auto 50px;
  @media screen and (max-width: 467px) {
    margin-top: 50px;
  }

  body.app--popup-style & {
    margin: 0 auto;
  }

  .page-head {
    text-align: center;
    margin-bottom: 20px;

    &__title {
      color: @color-darkgrey;
      font-family: @font-primary;
      font-size: 32px;
    }

    &__desc {
      font-family: @font-primary;
      line-height: 18px;
    }
  }

  &__sep-word {
    color: @color-lightgrey;
    display: block;
    font-size: 17px;
    margin-bottom: 20px;
    text-align: center;

    body.app--popup-style & {
      position: relative;

      span {
        background-color: #FFF;
        display: inline-block;
        font-family: @font-primary-bold;
        padding: 0 10px;
        position: relative;
        z-index: 9;
      }

      &:after {
        background-color: #979797;
        content: '';
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        z-index: 8;
      }
    }
  }

  &__fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .remember-field {
    body.app--popup-style & {
      float: left;
      margin-top: 15px;
    }
  }

  &__submit {
    display: block;
    height: 50px;
    margin: 30px auto 0;
    overflow: hidden;
    width: 100%;

    body.app--popup-style #loginForm & {
      display: inline-block;
      float: right;
      margin: 0 auto 30px;
      width: auto;
    }

    &:hover {
      background-color: @color-darkgrey;
      border-color: transparent;

      .btn-cta__label {
        color: #DEDEDE;
      }
    }
  }

  &__redirect-pro {
    margin-top: 20px;
    text-align: center;

    body.app--popup-style & {
      clear: both;
    }

    a {
      font-family: @font-primary-bold;
      text-decoration: underline;
    }
  }

  &__help-support {
    line-height: 18px;
    font-size: 12px;
    margin: 20px 0;
    text-align: center;
  }

  &__cgu {
    line-height: 18px;
    font-size: 12px;
  }

  &__alerts {
    color: @color-darkergrey;
    display: none;
    font-family: @font-primary-med;
    line-height: 22px;
    .em-size(16);
    margin: 30px 0;
    text-align: center;

    body.app--popup-style & {
      clear: both;
      overflow: hidden;
      margin: 0;
    }

    .alert-error {
      color: @color-red;
    }

    .alert-success {
      color: @color-gold;
    }
  }

  .contactHelpRhinov {
    color: @color-darkgrey;
    text-decoration: underline;
  }

  &__label-newsletter-mobile {
    display: none;
    margin-bottom: 10px;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}

.register-success-msg {
  color: @color-gold;
  font-family: @font-primary-med;
  line-height: 22px;
  .em-size(16);
  margin: 30px 0;
  text-align: center;
}

.activate-account-loading {
  font-family: @font-primary-med;
  font-style: italic;
  .em-size(16);
  margin: 30px 0;
  text-align: center;
}
