
.language-selector-wrapper {
    position: relative;
    display: inline-block;
}

.language-selector-button {

    margin: auto;
    background-color: transparent;
    border: 1px solid #3d3c40;
    color: #3d3c40;

    padding: 16px;
    font-size: 16px;
    border: 1px solid #3d3c40;

    &:hover {
        cursor: pointer;
    }
}

.language-selector-content {
    border-radius: 3px;
    border: 1px solid rgb(235, 235, 235);

    display: none;
    position: absolute;
    background-color: #fdfdfd;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;

    left:auto;
    right:0;
}

.language-selector-content div {
    padding: 12px 16px;
    display: block;
    cursor: pointer;
    background-color: white;

}

.language.selected {
    background-color: #CEB38E;
}

.language-selector-wrapper:hover .language-selector-content {
    display: block;
}

