body,
html {
  height: 100%;

  &.app--popup-style {
    height: auto;
  }
}

body {
  background-color: #FFF;
  color: @color-darkgrey;
  font-family: @font-primary;
  font-size: 14px;
  font-weight: 300;
}

a {
  color: @color-yellow;
  text-decoration: none;
  .transition(all ease-in-out .2s);

  &:focus,
  &:hover {
    color: darken(@color-yellow, 10%);
    text-decoration: none;
  }
}

.clearfix {

  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

.col-half {
  float: left;
  width: 48%;

  &:first-child {
    margin-right: 4%;
  }

  @media screen and (max-width: 467px) {
    float: none;
    width: 100%;
  }
}

.link-back {
  color: @color-darkgrey  !important;
  display: inline-block;
  .em-size(17);
  font-weight: 400;
  margin: 20px 0 20px;
  cursor: pointer;

  body.app--popup-style & {
    margin-left: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    .retina-sprite('arrow'@arrow @arrow-2x);
    margin-right: 5px;
    margin-bottom: 5px;
    vertical-align: -4px;
  }
}

.btn-cta {
  font-family: @font-primary-med;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  background-color: @color-darkgrey;
  color: @color-darkgrey;
  border: 1px solid transparent;
  .border-radius(0);
  display: inline-block;
  padding: 10px 40px;
  .transition(all ease-in-out .2s);

  &__surtitle {
    color: @color-darkgrey;
    display: block;
    font-family: @font-primary;
    .em-size(16);
    margin-bottom: 5px;
  }

  &__label {
    color: #FFF;
    display: inline-block;
    //text-transform: uppercase;
    .transition(all ease-in-out .2s);
  }

  &:hover {
    background-color: transparent;
    border: 1px solid @color-darkgrey;
    cursor: pointer;

    .btn-cta {
      &__label {
        color: @color-darkgrey;
      }
    }
  }

  &--with-icon {
    padding-top: 8px;
    padding-bottom: 0;

    .icon {
      display: inline-block;
      .em-size(24);
      vertical-align: -5px;
    }
  }

  &--outlined {
    width: 150px !important;
    background-color: transparent;
    border: 1px solid @color-darkgrey;
    color: @color-darkgrey;

    .btn-cta__label {
      color: white;
      text-transform: uppercase;
      font-weight: 500;
    }

    &:hover {
      background-color: @color-darkgrey;
      color: white;

      .btn-cta__label,
      .icon {
        color: #FFF;
      }
    }
  }

  &--primary {
    background-color: @color-yellow;

    .btn-cta__label {
      color: @color-darkgrey;
    }

    &:hover,
    &:focus {
      background-color: darken(@color-yellow, 10%);
      border-color: transparent;

      .btn-cta__label {
        color: #FFF;
      }
    }
  }
}

.fb-icon {
  &:before {
    content: '';
    display: inline-block;
    .retina-sprite('facebook'@facebook @facebook-2x);
    margin-right: 10px;
    margin-bottom: 3px;
    vertical-align: -6px;
  }
}

.google-icon {
  &:before {
    content: '';
    display: inline-block;
    .retina-sprite('google'@google @google-2x);
    margin-right: 10px;
    margin-bottom: 5px;
    vertical-align: -6px;
  }
}

.btn-login-social {
  color: #FFF;
  border: none;
  .border-radius(0);
  display: inline-block;
  height: 50px;
  font-size: 17px;
  font-family: @font-primary-med;
  margin: 10px 0;
  overflow: hidden;
  max-width: 465px;
  vertical-align: middle;
  width: 48%;

  @media screen and (max-width: 467px) {
    width: 100%;
    max-width: none;
  }

  &:hover {
    cursor: pointer;
  }

  &--fb {
    background-color: @color-fb;
    margin-right: 3%;
  }

  &--google {
    background-color: @color-google;
  }
}

.legal {
  margin: 20px 0;
  line-height: 1.7;
  font-size: 10px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.loader {
  display: block;
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: loader 1.1s infinite linear;
  animation: loader 1.1s infinite linear;
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.separator {
  border-bottom: solid 1px #D0D4D8;
  width: 100%;
  margin-bottom: 25px;
}

.rgpd-item:nth-child(2) {
  margin-top: 10px;
}

.label_comment {
  font-style: italic;
}


.clear-browsing-data {
  border-radius: 25px;
  padding: 10px;
  background-color: white;
  cursor: pointer;
  position: fixed;
  right: 10px;
  bottom: 10px;
  color: #cccccc;
  border: 1px solid rgb(218, 218, 218);
}

/* Popup related style */ 

.popup-background {
  display: none;
  background:rgba(0,0,0,.4);
  height: 100%;
  position: fixed;
  text-align: center;
  top:0;
  width:100%;
  z-index: 10;
}

.popup {
  margin: 200px auto;
  padding: 20px;
  font-size: 25px;
  border: 1px solid gray;
  border-radius: 30px;
  color: rgb(65, 65, 65);
  background-color: white;
  max-width: 200px;
}

.popup button {
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  background-color: rgb(65, 65, 65);
  border: 0px solid;
}

.popup button:hover {
  background-color: blue;
}

.password-container {
  position: relative;
}

.password-container img {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  opacity: 0.7;
}
