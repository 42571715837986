// Colors
@color-pink: #F8D3C5;
@color-yellow: #CEB491;
@color-mint: #ACE5D7;
@color-gold: #CFB48F;
@color-blue: #B8EFF9;
@color-lightblue: #FBFBFB;
@color-grey: #7C838A;
@color-darkgrey: #3D3C40;
@color-darkgrey2: #3C3B3F;
@color-darkergrey: #28282A;
@color-brown: #777679;
@color-lightgrey: #7D858C;
@color-beige-hover: #DADADA;
@color-red: #FF7E7E;
@color-fb: #4267b2;
@color-google: #D34836; //Fonts
@font-primary: 'fabrikat-regular';
@font-primary-med: 'fabrikat-medium';
@font-primary-bold: 'fabrikat-bold';
@font-secondary: 'Playfair Display', sans-serif;
