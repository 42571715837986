/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@arrow-name: 'arrow';
@arrow-x: 20px;
@arrow-y: 0px;
@arrow-offset-x: -20px;
@arrow-offset-y: 0px;
@arrow-width: 20px;
@arrow-height: 10px;
@arrow-total-width: 40px;
@arrow-total-height: 31px;
@arrow-image: '../img/sprite.png';
@arrow: 20px 0px -20px 0px 20px 10px 40px 31px '../img/sprite.png' 'arrow';
@facebook-name: 'facebook';
@facebook-x: 0px;
@facebook-y: 13px;
@facebook-offset-x: 0px;
@facebook-offset-y: -13px;
@facebook-width: 8px;
@facebook-height: 18px;
@facebook-total-width: 40px;
@facebook-total-height: 31px;
@facebook-image: '../img/sprite.png';
@facebook: 0px 13px 0px -13px 8px 18px 40px 31px '../img/sprite.png' 'facebook';
@google-name: 'google';
@google-x: 0px;
@google-y: 0px;
@google-offset-x: 0px;
@google-offset-y: 0px;
@google-width: 20px;
@google-height: 13px;
@google-total-width: 40px;
@google-total-height: 31px;
@google-image: '../img/sprite.png';
@google: 0px 0px 0px 0px 20px 13px 40px 31px '../img/sprite.png' 'google';
@icn-arrow-name: 'icn_arrow';
@icn-arrow-x: 8px;
@icn-arrow-y: 13px;
@icn-arrow-offset-x: -8px;
@icn-arrow-offset-y: -13px;
@icn-arrow-width: 6px;
@icn-arrow-height: 10px;
@icn-arrow-total-width: 40px;
@icn-arrow-total-height: 31px;
@icn-arrow-image: '../img/sprite.png';
@icn-arrow: 8px 13px -8px -13px 6px 10px 40px 31px '../img/sprite.png' 'icn_arrow';
@arrow-2x-name: 'arrow@2x';
@arrow-2x-x: 40px;
@arrow-2x-y: 0px;
@arrow-2x-offset-x: -40px;
@arrow-2x-offset-y: 0px;
@arrow-2x-width: 40px;
@arrow-2x-height: 20px;
@arrow-2x-total-width: 80px;
@arrow-2x-total-height: 62px;
@arrow-2x-image: '../img/sprite@2x.png';
@arrow-2x: 40px 0px -40px 0px 40px 20px 80px 62px '../img/sprite@2x.png' 'arrow@2x';
@facebook-2x-name: 'facebook@2x';
@facebook-2x-x: 0px;
@facebook-2x-y: 26px;
@facebook-2x-offset-x: 0px;
@facebook-2x-offset-y: -26px;
@facebook-2x-width: 16px;
@facebook-2x-height: 36px;
@facebook-2x-total-width: 80px;
@facebook-2x-total-height: 62px;
@facebook-2x-image: '../img/sprite@2x.png';
@facebook-2x: 0px 26px 0px -26px 16px 36px 80px 62px '../img/sprite@2x.png' 'facebook@2x';
@google-2x-name: 'google@2x';
@google-2x-x: 0px;
@google-2x-y: 0px;
@google-2x-offset-x: 0px;
@google-2x-offset-y: 0px;
@google-2x-width: 40px;
@google-2x-height: 26px;
@google-2x-total-width: 80px;
@google-2x-total-height: 62px;
@google-2x-image: '../img/sprite@2x.png';
@google-2x: 0px 0px 0px 0px 40px 26px 80px 62px '../img/sprite@2x.png' 'google@2x';
@icn-arrow-2x-name: 'icn_arrow@2x';
@icn-arrow-2x-x: 16px;
@icn-arrow-2x-y: 26px;
@icn-arrow-2x-offset-x: -16px;
@icn-arrow-2x-offset-y: -26px;
@icn-arrow-2x-width: 12px;
@icn-arrow-2x-height: 20px;
@icn-arrow-2x-total-width: 80px;
@icn-arrow-2x-total-height: 62px;
@icn-arrow-2x-image: '../img/sprite@2x.png';
@icn-arrow-2x: 16px 26px -16px -26px 12px 20px 80px 62px '../img/sprite@2x.png' 'icn_arrow@2x';
@spritesheet-width: 40px;
@spritesheet-height: 31px;
@spritesheet-image: '../img/sprite.png';
@spritesheet-sprites: @arrow @facebook @google @icn-arrow;
@spritesheet: 40px 31px '../img/sprite.png' @spritesheet-sprites;
@retina-spritesheet-width: 80px;
@retina-spritesheet-height: 62px;
@retina-spritesheet-image: '../img/sprite@2x.png';
@retina-spritesheet-sprites: @arrow-2x @facebook-2x @google-2x @icn-arrow-2x;
@retina-spritesheet: 80px 62px '../img/sprite@2x.png' @retina-spritesheet-sprites;

/*
These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
*/
@arrow-group-name: 'arrow';
@arrow-group: 'arrow' @arrow @arrow-2x;
@facebook-group-name: 'facebook';
@facebook-group: 'facebook' @facebook @facebook-2x;
@google-group-name: 'google';
@google-group: 'google' @google @google-2x;
@icn-arrow-group-name: 'icn_arrow';
@icn-arrow-group: 'icn_arrow' @icn-arrow @icn-arrow-2x;
@retina-groups: @arrow-group @facebook-group @google-group @icn-arrow-group;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  background-image: e(%('url(%a)', e(@sprite-image)));
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `retina-sprite` class sets up rules and a media query for a sprite/retina sprite.
  It should be used with a "retina group" variable.

The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

@icon-home-group: 'icon-home' @icon-home @icon-home-2x;

.icon-home {
  .retina-sprite(@icon-home-group);
}
*/
.sprite-background-size(@sprite) {
  @sprite-total-width: extract(@sprite, 7);
  @sprite-total-height: extract(@sprite, 8);
  background-size: @sprite-total-width @sprite-total-height;
}

.retina-sprite(@retina-group) {
  @normal-sprite: extract(@retina-group, 2);
  @retina-sprite: extract(@retina-group, 3);
  .sprite(@normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    .sprite-image(@retina-sprite);
    .sprite-background-size(@normal-sprite);
  }
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}

/*
The `.retina-sprites` mixin generates a CSS rule and media query for retina groups
  This yields the same output as CSS retina template but can be overridden in LESS

.retina-sprites(@retina-groups);
*/
.retina-sprites(@retina-groups, @i: 1) when (@i <= length(@retina-groups)) {
  @retina-group: extract(@retina-groups, @i);
  @sprite-name: e(extract(@retina-group, 1));
  .@{sprite-name} {
    .retina-sprite(@retina-group);
  }
  .retina-sprites(@retina-groups, @i + 1);
}
