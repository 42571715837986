.auth-fieldset {

  &__error-details {
    color: @color-red;
    font-weight: 500;
    .em-size(13);

    ul {
      padding: 0;
      margin: 8px 0 10px;

      li {
        list-style: none;
      }
    }
  }
}

.text-field {
  position: relative;
  margin-bottom: 20px;

  &--pwd {
    padding-top: 18px;
  }

  &__label {
    color: @color-darkgrey;
    display: none;
    .em-size(12);
    font-weight: 500;
    margin-bottom: 5px;
    text-transform: uppercase;

    body.app--popup-style & {
      display: none;
    }

    &--right {
      display: block !important;
      position: absolute;
      top: 0;
      right: 0;
      text-transform: none;

      body.app--popup-style & {
        display: inline;
      }
    }

    &--underline {
      text-decoration: underline;
    }
  }

  &__input {
    background-color: #EBEBEB;
    box-sizing: border-box;
    border: 1px solid transparent;
    color: @color-darkgrey;
    font-family: @font-primary;
    .em-size(16);
    font-weight: 400;
    padding: 10px 20px;
    outline: none;
    width: 100%;
    .transition(all ease-in-out .2s);

    &:focus {
      box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      border-color: @color-gold;
    }

    &.parsley-error {
      border-color: @color-red;
    }

    .placeholder(#9E9E9E);

    /*  There is a bug in chrome that prevents several characters to be displayed correctly
        in their uppercase form, like Ñ, Á, Ó ...
    &::-webkit-input-placeholder {
       text-transform: uppercase;
    }
    */

    &:-moz-placeholder {
       text-transform: uppercase;
    }

    &::-moz-placeholder {
       text-transform: uppercase;
    }

    &:-ms-input-placeholder {
       text-transform: uppercase;
    }
  }

  &__submit {
    display: inline-block;
    padding: 10px 20px;
    margin-left: -3px;
  }

  &__textarea {
    background-color: #EBEBEB;
    box-sizing: border-box;
    border: 1px solid transparent;
    color: @color-darkgrey;
    font-weight: 400;
    resize: vertical;
    padding: 10px;
    outline: none;
    min-height: 150px;
    width: 100%;
    .transition(all ease-in-out .2s);

    &:focus {
      box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      border-color: @color-gold;
    }
  }

  &__select {
    float:right;
    background-color: #EBEBEB;
    border: 1px solid transparent;
    color: @color-darkgrey;
    display: block;
    padding: 10px 20px;
    position: relative;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      box-shadow: 0 0 5px rgba(0, 0, 0, .3);
      border-color: @color-gold;
    }
  }
}

.checkbox-group {
  margin-top: 10px;
  margin-bottom: 20px;
}

.custom-checkbox,
.custom-radio {
  position: absolute;
  left: -9999px;

  & + label {
    color: @color-darkgrey;
    font-size: 1em;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    padding-left: 30px;
    display: block;
    cursor: pointer;

    &:before {
      background: #FFF;
      border: 1px solid @color-gold;
      content: '';
      height: 19px;
      position: absolute;
      top: -2px;
      left: 0;
      width: 19px;
    }

    &:after {
      background: @color-yellow;
      content: '';
      display: block;
      font-size: 14px;
      height: 15px;
      position: absolute;
      top: 1px;
      left: 3px;
      width: 15px;
      .transition(.2s ease-in-out all);
    }
  }

  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  &:disabled {
    &:checked + label:before,
    &:not(:checked) + label:before {
      box-shadow: none;
      border-color: #999999;
      background-color: #ddd;
    }

    &:checked + label:after {
      color: #999;
    }

    & + label {
      color: #aaa;
    }
  }

  /* accessibility */
  &:checked:focus + label:before,
  input[type="checkbox"]:not(:checked):focus + label:before {
    // border: inherit;
  }
}

.custom-radio {
  & + label {
    &:after,
    &:before {
      .border-radius(100%);
    }
  }
}

.checkbox-inline,
.radio-inline {
  float: right;
  padding-left: 0;
  margin-bottom: 10px;
  margin-left: 10px;
}

.select-group {
  width: 100%;
  float: left;
  position: relative;

  &__legend {
    float: left;
    margin-bottom: 20px;
    padding-top: 10px;
    &--mobile {
      display: none;
    }
    &--desktop {
      display: block;
    }
    @media screen and (max-width: 768px) {
      &--mobile {
        display: block;
      }
      &--desktop {
        display: none;
      }
    }

  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    right: 7px;
    top: 15px;
    transform: rotate(90deg);
    .retina-sprite('icn_arrow' @icn-arrow @icn-arrow-2x);
  }

  .auth-fieldset__error-details {
    float: right;
  }
}
